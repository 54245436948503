import { BrowserModule } from '@angular/platform-browser';
import { NgModule,Injector } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsModalModule } from 'ng2-bs3-modal';
import { BnNgIdleService } from 'bn-ng-idle';

import { AppRoutingModule } from './app-routing.module';
//import { ActivatedRouteSnapshot, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { AppComponent } from './app.component';
import { Adal6Service } from 'adal-angular6';
import { AuthService } from './shared/auth.service';
import { WSO2Service } from './shared/wso2.service';
import { UserService } from './shared/user.service';
//import { LogService } from './shared/log.service';
import { AuthGuardService } from './shared/auth-guard.service';
import { AdminOrMasterUserGuardService } from './shared/admin-or-master-user-auth-guard.service';
import { HttpClientInterceptor } from './shared/httpclient.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { GridModule } from '@progress/kendo-angular-grid';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { ToastModule, ToastOptions } from 'ng2-toastr/ng2-toastr';

import { HomeModule } from './home/home.module';
import { HeaderModule } from './header/header.module';
import { FooterModule } from './footer/footer.module';
import { MyCalendarComponent } from './my-calendar/my-calendar.component';
//import { MyTasksComponent } from './my-tasks/my-tasks.component';
//import { MyLoansComponent } from './my-loans/my-loans.component';
//import { ReportingComponent } from './reporting/reporting.component';
//import { EmailConfigurationComponent } from './administration/email-configuration/email-configuration.component';
//import { MaintainUserDetailsComponent } from './administration/maintain-user-details/maintain-user-details.component';
//import { UsersGridComponent } from './administration/users-grid/users-grid.component';
//import { UserDetailsComponent } from './administration/user-details/user-details.component';
//import { MaintainLegalEntityComponent } from './administration/maintain-legal-entity/maintain-legal-entity.component';
//import { MaintainUserObjectsComponent } from './administration/maintain-user-objects/maintain-user-objects.component';
import { AppMaterialModule } from './app.material.module';
import { LegalEntityService } from './http-services/legal-entity.service';
import { NewEditLegalEntityComponent } from './administration/legal-entity-details/new-edit-legal-entity.component';
import { MatChipsModule } from '@angular/material/chips';
//import { CommonConfirmationDialog } from './common/common.confirmation.dialog.component';
import { CommonAlertDialog } from './common/common.alert.dialog.component';
import { ClientDisplayPipe } from './common/pipes/clinet.display.pipe';
import { UserDisplayPipe } from './common/pipes/user.display.pipe';
import { ToastrModule } from 'ngx-toastr';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
//Tree
import {
  MatIconModule, MatButtonModule, MAT_DIALOG_DEFAULT_OPTIONS,
  MAT_DIALOG_DATA, MatExpansionModule, MatCommonModule,
  MatInputModule, MatDialogRef
} from '@angular/material';
//import { TreeviewModule } from 'ngx-treeview';
import { TreeModule } from 'angular-tree-component';
//Calendar
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
//Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//Tree
//import { TreeComponent } from './common/tree/tree-view.component';
//Spinner
import { SpinnerComponent } from './common/spinner/spinner.component';
import { SpinnerService } from './shared/spinner.service';
//Vendor Management
//import { MaintainVendorComponent } from './administration/maintain-vendor/maintain-vendor.component';
//import { VendorDetailsComponent } from './administration/vendor-details/vendor-details.component';
//import { VendorService } from './http-services/vendor.service';
//import { ClientDetailsComponent } from './administration/client-details/client-details.component';
import { ClientService } from './http-services/client.service';
//import { MaintainIncidentComponent } from './administration/maintain-incident/maintain-incident.component';
//import { IncidentDetailsComponent } from './administration/incident-details/incident-details.component';
//import { IncidentCommentsComponent } from './administration/incident-comments/incident-comments.component';
//import { UserObjectDetailsComponent } from './administration/user-object-details/user-object-details.component';
//import { NewUserDetailsComponent } from './administration/new-user-details/new-user-details.component';
//import { ExternalUserDetailsComponent } from './administration/external-user-details/external-user-details.component';
//import { NewObjectDetailsComponent } from './administration/new-object-details/new-object-details.component';
//import { SettingsDetailsComponent } from './administration/settings-details/settings-details.component';
//import { UserPreferencesComponent } from './administration/maintain-settings/user.preferences.component';

//import { IncidentService } from './http-services/incident.service';
import { TooltipModule } from "ngx-tooltip";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
//import { PriorityDisplayPipe } from './common/pipes/priority.display.pipe';
//import { IncidentStatusDisplayPipe } from './common/pipes/incidentStatus.display.pipe';
//import { VendorDisplayPipe } from './common/pipes/vendor.display.pipe';
//import { IncidentInvoicePODisplayPipe } from './common/pipes/incidentInvoicePO.display.pipe';
//import { LegalEntityDisplayPipe } from './common/pipes/legalEntity.display.pipe';
//import { UploadAttachmentComponent } from './administration/upload-attachment/upload-attachment.component';
//import { AssociateIncidentComponent } from './administration/associate-incident/associate-incident.component';
//import { ApproverDetailsComponent } from './administration/approver-details/approver-details.component';
//import { MaintainApproverComponent } from './administration/maintain-approver/maintain-approver.component';
//import { ApproverService } from './http-services/approver.service';
//import { DisableControlDirective } from './common/directives/disable-control.directive';
//import { ExcelService } from './shared/import-export-services/excel.service';
//import { ApproverEmailConfigurationComponent } from './administration/approver-email-configuration/approver-email-configuration.component';
//import { PageSettingService } from './shared/page-settings.service';
//import { PageNavService } from './shared/page-nav.service';
//import { MaintainObjectComponent } from './administration/maintain-object/maintain-object.component';
//import { AddFolderComponent } from 'src/app/administration/add-folder/add-folder.component';
//import { from } from 'rxjs';
//import { LoanDetailsComponent } from './loan-details/loan-details.component';
import { ComponentCommunication } from './shared/component-communication.service';
//import { AddEditLoanDetailsComponent } from './add-edit-loan/add-edit-loan.component';
import { MyDatePickerModule } from 'mydatepicker';
import { NgxCurrencyModule } from 'ngx-currency';
//import { AddLoanToClient } from 'src/app/add-loanTo-client/add-loanTo-client.component';
//import { LoanDetailsService } from './http-services/loan-details.service';
//import { CovenantDetailsComponent } from './add-edit-covenant/covenant-details.component';
//import { CovenantAddEditComponent } from './covenant-details-modal/covenant-add-edit.component';
//import { CovenantService } from './http-services/covenant.service';
//import { DocumentComponent } from './document-section/document.component';
//import { MaterialComponent } from './material-table-component/material-table.component';
//import { CovenantWholeFormComponent } from './covenant-details/covenant-whole-form.component';
//import { ChangeRequestComponent } from './change-request-modal/change-request.component';
import { DateDisplayPipe } from 'src/app/common/pipes/date.display.pipe';
import { LoanProcessComponent } from './administration/loan-process/loan-process.component';
import { UserPreferenceService } from './shared/user.pref.service';
import { ErrorDialogService } from './shared/error-dialog.service';
import { CommonConfirmationDialog } from './common/common.confirmation.dialog.component';
import { Injectable } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import {
  OKTA_CONFIG,
  OktaAuthGuard,
  OktaAuthModule,
  OktaCallbackComponent,
} from '@okta/okta-angular';
import { environment } from '../environments/environment';
import { CommonModule } from '@angular/common';

const config = {
  issuer: environment.OktaDomain,
  clientId: environment.OktaClientId,
  redirectUri: environment.OktaRedirectUri+'/login/callback'
}
const oktaAuth = new OktaAuth(config);

@NgModule({
  declarations: [
    AppComponent,
    MyCalendarComponent,
    //MyTasksComponent,
    //MyLoansComponent,
    //ReportingComponent,
    //MaintainUserDetailsComponent,
    //MaintainLegalEntityComponent,
    //MaintainUserObjectsComponent,
    //EmailConfigurationComponent,
    //UserObjectDetailsComponent,
    //NewObjectDetailsComponent,
    //NewUserDetailsComponent,
    //ExternalUserDetailsComponent,
    //UsersGridComponent,
    //UserDetailsComponent,
    DateDisplayPipe,
    NewEditLegalEntityComponent,
    CommonConfirmationDialog,
    CommonAlertDialog,
    //AddFolderComponent,
    ClientDisplayPipe,
    UserDisplayPipe,
    // PriorityDisplayPipe,
    //IncidentInvoicePODisplayPipe,
    //IncidentStatusDisplayPipe,
    //VendorDisplayPipe,
    //LegalEntityDisplayPipe,
    CommonAlertDialog,
    //MaintainVendorComponent,
    //MaintainIncidentComponent,
    //VendorDetailsComponent,
    //ClientDetailsComponent,
    SpinnerComponent,
    LoanProcessComponent,
    //TreeComponent,
    //IncidentDetailsComponent,
    //IncidentCommentsComponent,
    //UploadAttachmentComponent,
    //AssociateIncidentComponent,
    // MaintainApproverComponent,
    //ApproverDetailsComponent,
    //DisableControlDirective,
    //ApproverEmailConfigurationComponent,
    //MaintainObjectComponent,
    //LoanDetailsComponent,
    //AddEditLoanDetailsComponent,
    //AddLoanToClient,
    //CovenantDetailsComponent,
    //CovenantAddEditComponent,
    //ChangeRequestComponent,
    //DocumentComponent,
    //MaterialComponent,
    //CovenantWholeFormComponent,
    //UserPreferencesComponent,
    // SettingsDetailsComponent
  ],
  imports: [
    BrowserModule,
    MyDatePickerModule,
    BrowserAnimationsModule,
    MatChipsModule,
    NgbModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BsModalModule,
    AppMaterialModule,
    //TreeviewModule.forRoot(),
    TreeModule.forRoot(),
    MatExpansionModule,
    MatCommonModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    //ActivatedRoute,
    HttpClientModule,
    GridModule,
    HomeModule,
    HeaderModule,
    FooterModule,
    DateInputsModule,
    LoggerModule.forRoot({ serverLoggingUrl: '/api/logs', level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR }),
    ToastrModule.forRoot({
      timeOut: 3500,
      //positionClass: ToasterPosition.topCenter,
      preventDuplicates: true,
      enableHtml: true,
      closeButton: true,
      progressBar: true,
      // toastClass:'cdmtoastClass',
      positionClass: 'cdmpositionClass',
      // titleClass:'cdmtitleClass',
      // messageClass:'cdmmessageClass'
    }),
    TooltipModule,
    CKEditorModule,
    NgxCurrencyModule,
    OktaAuthModule
  ],
  exports: [
    AppMaterialModule,
    MatExpansionModule,
    MatChipsModule
  ],
  providers: [
    AuthGuardService,
    AdminOrMasterUserGuardService,
    BnNgIdleService,
    AuthService,
    WSO2Service,
    UserService,
    //LogService,
    Adal6Service,
    //LoanDetailsService,
    SpinnerService,
    LegalEntityService,
    //VendorService,
    ClientService,
    //CovenantService,
    //IncidentService,
    //ApproverService,
    //ExcelService,
    //PageSettingService,
    //PageNavService,
    ComponentCommunication,
    UserPreferenceService,
    ErrorDialogService,
    [
      { provide: MatDialogRef, useValue: {} },
      { provide: MAT_DIALOG_DATA, useValue: [] },
      { provide: HTTP_INTERCEPTORS, useClass: HttpClientInterceptor, multi: true },
      {
        provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useValue:
        {
          hasBackdrop: true,
          disableClose: true,
          closeOnNavigation: true,
          panelClass: 'cdmPanelClass'
        }
      }],
      [{ 
        provide: OKTA_CONFIG, 
        useValue: { oktaAuth } 
      }]
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    CommonAlertDialog,
    CommonConfirmationDialog
    //UserObjectDetailsComponent,
    //NewUserDetailsComponent,
    //ExternalUserDetailsComponent,
    //NewObjectDetailsComponent,
    //NewEditLegalEntityComponent,
    //SettingsDetailsComponent,
    //VendorDetailsComponent,
    //CommonConfirmationDialog,
    //CommonAlertDialog,
    //AddFolderComponent,
    //TreeComponent,
    //ClientDetailsComponent,
    //UploadAttachmentComponent,
    //AssociateIncidentComponent,
    //ApproverDetailsComponent,
    //AddLoanToClient,
    //CovenantAddEditComponent,
    //ChangeRequestComponent,
    //DocumentComponent,
    //MaterialComponent
  ],
})
export class AppModule { }
