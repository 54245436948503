// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { VERSION } from './version';

export const environment = {
  production: false,
  apiUrl: 'https://api-test.cbre.com:443/t/advisory/cdmapi-qa/1.0.0.8',//'https://cdm-api-qa.cbre.com',
  tenant: "cbrelabsext.onmicrosoft.com",
  instance: 'https://cbrelabsEXT.b2clogin.com/',
  clientId: "5d34f7cc-013b-4aee-a50b-c79fc19cda92",   //Non-prod
  authnPolicy: "b2c_1a_giaidp_signup_signin",
  wso2TokenURL: "https://api-test.cbre.com:443/token",
  wso2ClientId: "8M0CXevRKTho5iq45gQfe7EXQ50a",
  wso2ClientSecret: "AFbeKfelaVM2mDyCEDUYxff7KSMa",
  OktaDomain: "https://login-uat.cbre.com/oauth2/aus22ui6kkc3zIlMI0h8",
  OktaClientId: "0oa1fbcpewiEh20oC0h8",
  OktaRedirectUri: 'https://cdm-qa.cbre.com',
  Oktascopes: ['openid', 'profile', 'email'],
  IsOktaAuthentication: "true",
  appVersion: VERSION.version,
  minutesUntilAutoLogout: 25
};

/*
  * In development mode, to ignore zone related error stack frames such as
  * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
  * import the following file, but please comment it out in production mode
  * because it will have performance impact when throw error
  */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

